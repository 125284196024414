/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}
:root {
  background: #000000;
}
/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.nft1 {
  transition: all 200ms ease-in-out;
}
.nft1:hover {
  transform: scale(1.05);
  -webkit-box-shadow: 0 0 25px -8px rgb(108, 201, 194);
  -moz-box-shadow: 0 0 25px -8px rgb(108, 201, 194);
  box-shadow: 0 0 25px -8px rgb(108, 201, 194 /0.02);
}
.nft2 {
  transition: all 200ms ease-in-out;
}
.nft2:hover {
  transform: scale(1.05);
  -webkit-box-shadow: 0 0 25px -8px rgb(161, 78, 53);
  -moz-box-shadow: 0 0 25px -8px rgb(161, 78, 53);
  box-shadow: 0 0 25px -8px rgb(161, 78, 53);
}
.ctaButton {
  margin-top: 10;
  margin-bottom: -10;
  color: '#000000';
  background-color: '#ffffff';
  border: '1px solid #000000';
  border-radius: 2;
  font-size: 16;
  font-weight: 'light';
}
.ctaButton:hover {
  color: '#ffffff';
  background-color: '#000000';
  border: '1px solid #ffffff';
}
@media (min-width: 300px) {
  #mobile-image {
    display: block;
  }
  #tablet-image {
    display: none;
  }
  .mobile {
    display: block;
  }
  .tablet {
    display: none;
  }
}
@media (min-width: 768px) {
  #mobile-image {
    display: none;
  }
  #tablet-image {
    display: block;
  }
  .tablet {
    display: block;
  }
  .mobile {
    display: none;
  }
}
@font-face {
  font-family: 'Goldenbook';
  src: url('./assets/font/Goldenbook\ Regular.otf');
}
@font-face {
  font-family: 'Dual';
  src: url('./assets/font/Roboto-Light.ttf');
}
h1 {
  font-family: 'Goldenbook', sans-serif;
  font-weight: normal;
  font-style: normal;
}
h3 {
  font-family: 'Dual', sans-serif;
  font-weight: normal;
  font-style: normal;
}
p {
  font-family: 'Roboto', sans-serif;
  font-weight: light;
  font-style: normal;
}
.container {
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0px;
  width: 100vw;
  height: 100vh;
}

.capsule-div {
  width: 768px;
  height: auto;
  display: flex;
  position: absolute;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.custom-info-div {
  width: 280px;
  height: 280px;
  display: flex;
  justify-content: center;
  position: absolute;
  margin: 0 auto;
  padding-bottom: 0px;
  flex-direction: column;
}
.disclaimer-div {
  width: 'vw80';
  display: flex;
  justify-content: center;
  position: absolute;
  margin: 0 auto;
  padding-bottom: 0px;
  flex-direction: column;
}

h1,
h3 {
  color: white;
}
